<template>
	<div id="news">
		<Header :title="$t('news.header.title')" :description="$t('news.header.description')" />
		<div v-if="News.length > 0" class="container mx-auto px-5 lg:px-8 grid grid-cols-1 lg:grid-cols-3 py-12 gap-8">
			<div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 col-span-3 my-8 sm:my-12 md:my-16">
				<div>
					<img loading="lazy" class="w-full border border-secondry object-cover" alt="" :src="'https://' + News[0].photo[0].path" />
				</div>
				<div class="">
					<div class="flex items-center text-dateText my-1 tracking-wide w-full">
						<span>{{ $i18n.locale == 'ar' ? News[0].category_ar : News[0].category_en }} - </span>
						<span class="mx-1">
							{{ $moment(News[0].created_at).format('YYYY-MM-DD') }}
						</span>
					</div>
					<h3 class="text-footerBg text-2xl my-4 font-bold w-full ">
						{{ $i18n.locale == 'ar' ? News[0].title_ar : News[0].title_en }}
					</h3>
					<p
						v-html="$i18n.locale == 'ar' ? News[0].description_ar : News[0].description_en"
						class="text-newsDescription line-clamp-description tracking-wide text-lg font-medium my-3 w-full"
					></p>
					<router-link
						:to="{ name: 'newsDetails', params: { id: News[0].id } }"
						class="text-btn flex items-center font-medium tracking-wide my-5 w-full"
					>
						<span> {{ $t('news.readmore') }}</span>
						<img
							:class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
							class="mx-2 object-cover"
							src="../assets/VectorBlue.svg"
							alt=""
							loading="lazy"
						/>
					</router-link>
				</div>
			</div>
			<NewsComponent
				class="col-span-3 md:col-span-1"
				v-for="(item, index) in News"
				:key="index"
				:news="item"
				data-aos="fade-up"
				data-aos-duration="600"
				data-aos-easing="ease-in-out"
			/>
		</div>
		<div v-else class="flex items-center justify-center my-12">
			<i class="bx bx-loader-circle bx-spin text-5xl text-primary"></i>
		</div>
	</div>
</template>
<script>
import Header from '@/components/layout/Header';
import NewsComponent from '@/components/utils/NewsComponent.vue';
import lineClamp from 'line-clamp';

export default {
	name: 'News',
	data() {
		return {
			URL: process.env.VUE_APP_URL,
		};
	},
	components: {
		Header,
		NewsComponent,
	},
	created() {
		this.$store.dispatch('News');
	},
	mounted() {
		const description = document.querySelector('.line-clamp-description');
		lineClamp(description, 12);
	},
	computed: {
		News() {
			return this.$store.getters.News;
		},
	},
};
</script>
<style>
.line-clamp-description {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 12;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>